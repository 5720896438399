import { LocalStorageKey } from "@/src/enums/common";
import { PIM_LOCAL_STORAGE_PREFIX } from "@/src/constants/localStorage";

const PERSISTED_LOCAL_STORAGE_KEYS = [
  LocalStorageKey.PdpImageAssetsTour,
  LocalStorageKey.CountryList,
  LocalStorageKey.CurrencyList,
  LocalStorageKey.GoogleCategory,
];

export const clearAllPimLocalStorage = () => {
  Object.values(LocalStorageKey)
    .filter((key) => !PERSISTED_LOCAL_STORAGE_KEYS.includes(key))
    .forEach((key) => {
      const keyWithPrefix = `${PIM_LOCAL_STORAGE_PREFIX}${key}`;
      localStorage.removeItem(keyWithPrefix);
    });
};
